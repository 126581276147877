import React, { createContext, useContext, useState, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

type Notification = {
	message: string;
	severity?: "success" | "info" | "warning" | "error";
};

type NotificationContextType = {
	notify: (message: string, severity?: Notification["severity"]) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(
	undefined
);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [notification, setNotification] = useState<Notification | null>(null);

	const notify = (
		message: string,
		severity: Notification["severity"] = "info"
	) => {
		setNotification({ message, severity });
	};

	const handleClose = () => {
		setNotification(null);
	};

	return (
		<NotificationContext.Provider value={{ notify }}>
			{children}
			<Snackbar
				open={!!notification}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				{/* Ensure Snackbar always has a valid child */}
				{notification ? (
					<Alert
						onClose={handleClose}
						severity={notification.severity}
						variant="filled"
					>
						{notification.message}
					</Alert>
				) : (
					<div style={{ display: "none" }} /> // Dummy element to avoid errors
				)}
			</Snackbar>
		</NotificationContext.Provider>
	);
};

export const useNotification = () => {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error(
			"useNotification must be used within a NotificationProvider"
		);
	}
	return context;
};
