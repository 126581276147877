import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore, Add } from "@mui/icons-material";

import { BudgetItem } from "../../utils/types";
import BudgetElement from "../../components/BudgetElement/BudgetElement";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loaders";
import { useAuth } from "../../hooks/auth-hooks";
import { useBudgetContext } from "../../hooks/useBudgetContext";
import { useCreateBudget } from "../../hooks/budget-hooks";
import "../EditBudget/EditBudget.scss";

const AddNew = () => {
	const navigate = useNavigate();
	const { currentUser } = useAuth();
  const { noBudgets } = useBudgetContext();
  const { 
    mutateAsync: createBudget, 
    isSuccess, 
    isPending 
  } = useCreateBudget()

	const [budgetTitle, setBudgetTitle] = useState("");
	const [isFavorite, setIsFavorite] = useState(true);
	const [showExpenses, setShowExpenses] = useState(true);
	const [showIncomes, setShowIncomes] = useState(true);
	const [expenseArray, setExpenseArray] = useState<BudgetItem[]>([]);
	const [incomeArray, setIncomeArray] = useState<BudgetItem[]>([]);

	const expenseItem: BudgetItem = {
		type: "Expense",
		category: "",
		label: "",
		dueDate: 1,
		frequency: "Monthly",
		amount: 0,
	};

	const incomeItem: BudgetItem = {
		type: "Income",
		category: "",
		label: "",
		dueDate: 1,
		frequency: "Monthly",
		amount: 0,
	};

	useEffect(() => {
		setIncomeArray([{ ...incomeItem }]);
		setExpenseArray([{ ...expenseItem }]);
	}, []);

	useEffect(() => {
		if (isSuccess) {
			navigate("/");
		}
	}, [isSuccess]);

	// function to add a new section to a expenses
	const addExpenseSection = () => {
		setExpenseArray([...expenseArray, { ...expenseItem }]);
	};

	// function to add a new section to a incomes
	const addIncomeSection = () => {
		setIncomeArray([...incomeArray, { ...incomeItem }]);
	};

	const incomeSectionChanged = (
		type: string,
		val: any,
		multiple: number,
		index: number
	) => {
		if (type === "dueDate" && (val > 31 || val < 1)) {
			return;
		}
		let temp: any = { ...incomeArray[index] };
		if (multiple) {
			if (multiple === 2) {
				if (Array.isArray(temp[type as string])) {
					temp[type as string] = [
						temp[type as string][temp[type as string].length - 1],
						val,
					];
				} else {
					temp[type as string] = [val];
				}
			}
		} else {
			if (type === "frequency" && val === "annually") {
				temp[type as string] = val;
				temp["dueDate"] = ["1", 1];
			} else if (type === "frequency") {
				temp["dueDate"] = 1;
			}
			if (temp["frequency"] == "annually" && type == "dueDate") {
				if (typeof val === "number") {
					temp[type as string] = [temp[type as string][0], val];
				} else {
					temp[type as string] = [val, temp[type as string][1]];
				}
			} else {
				temp[type as string] = val;
			}
		}
		let tempArray = [...incomeArray];
		tempArray[index] = temp;
		setIncomeArray(tempArray);
	};

	const expenseSectionChanged = (
		type: string,
		val: any,
		multiple: number,
		index: number
	) => {
		if (type === "dueDate" && (val > 31 || val < 1)) {
			return;
		}
		let temp: any = { ...expenseArray[index] };
		if (multiple) {
			if (multiple === 2) {
				if (Array.isArray(temp[type as string])) {
					temp[type as string] = [
						temp[type as string][temp[type as string].length - 1],
						val,
					];
				} else {
					temp[type as string] = [val];
				}
			}
		} else {
			if (type === "frequency" && val === "annually") {
				temp[type as string] = val;
				temp["dueDate"] = ["1", 1];
			} else if (type === "frequency") {
				temp["dueDate"] = 1;
			}
			if (temp["frequency"] == "annually" && type == "dueDate") {
				if (typeof val === "number") {
					temp[type as string] = [temp[type as string][0], val];
				} else {
					temp[type as string] = [val, temp[type as string][1]];
				}
			} else {
				temp[type as string] = val;
			}
		}
		let tempArray = [...expenseArray];
		tempArray[index] = temp;
		setExpenseArray(tempArray);
	};

	const deleteButtonClicked = (index: number, type: string) => {
		if (type === "income") {
			let tempArray = [...incomeArray];
			tempArray.splice(index, 1);
			setIncomeArray(tempArray);
		} else {
			let tempArray = [...expenseArray];
			tempArray.splice(index, 1);
			setExpenseArray(tempArray);
		}
	};

	const saveBudgetClicked = () => {
		let budgetObj = {
			title: budgetTitle,
			created_at: new Date(),
			expenses: [...expenseArray],
			income: [...incomeArray],
			user_id: currentUser?.uid,
			favorite: isFavorite,
		};
		console.log("budgetObj", budgetObj);
		createBudget({ ...budgetObj });
	};

	return (
		<div className="edit-wrapper">
			{isPending ? (
				<Loader height="80vh" width="100%" />
			) : (
				<>
					<div className="edit-header edit-form-heading">
						<h1>
							{budgetTitle.length
								? budgetTitle
								: "Untitled Budget"}
						</h1>
					</div>
					<div className="edit-form">
						<div className="budget-form-fieldset">
							<div className="form-title">
								<p>Enter a title for your budget</p>
								<Input
									type="text"
									value={budgetTitle}
									onChange={(e: string) => setBudgetTitle(e)}
								/>
							</div>
							<div className="form-favorite">
								{/* <p>Mark as favorite?</p>
                <input type="checkbox" /> */}
								<p>Mark as favorite?</p>
								<Input
									type="checkbox"
									checked={isFavorite}
									onChange={() => setIsFavorite(!isFavorite)}
								/>
							</div>
						</div>
						<div className="income-section">
							<div
								className="section-header"
								onClick={() => setShowIncomes(!showIncomes)}
							>
								<p>Income</p>
								{showIncomes ? <ExpandMore /> : <ExpandLess />}
							</div>
							<div
								className={`income-section-holder ${
									showIncomes ? "show-section" : ""
								}`}
							>
								{incomeArray.length
									? incomeArray.map(
											(
												item: BudgetItem,
												index: number
											) => {
												return (
													<div
														key={index}
														className=""
													>
														<BudgetElement
															type={item.type}
															frequency={
																item.frequency
															}
															amount={item.amount}
															dueDate={
																item.dueDate
															}
															category={
																item.category
															}
															label={item.label}
															labelPosition="top"
															onChange={(
																type: string,
																val: any,
																multiple: number = 0
															) =>
																incomeSectionChanged(
																	type,
																	val,
																	multiple,
																	index
																)
															}
															onDelete={() =>
																deleteButtonClicked(
																	index,
																	"income"
																)
															}
														/>
													</div>
												);
											}
									  )
									: ""}
								<div className="edit-form-add-btn-wrapper">
									<div
										onClick={addIncomeSection}
										className="edit-form-add-btn"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Add sx={{ marginRight: 0.5 }} />
										<p>New Income</p>
									</div>
								</div>
							</div>
						</div>
						<div className="expense-section">
							<div
								className="section-header"
								onClick={() => setShowExpenses(!showExpenses)}
							>
								<p>Expenses</p>
								{showExpenses ? <ExpandMore /> : <ExpandLess />}
							</div>{" "}
							<div
								className={`expense-section-holder ${
									showExpenses ? "show-section" : ""
								}`}
							>
								{expenseArray.length
									? expenseArray.map(
											(
												item: BudgetItem,
												index: number
											) => {
												return (
													<div key={index}>
														<BudgetElement
															type={item.type}
															frequency={
																item.frequency
															}
															amount={item.amount}
															dueDate={
																item.dueDate
															}
															category={
																item.category
															}
															label={item.label}
															labelPosition="top"
															onChange={(
																type: string,
																val: any,
																multiple: number = 0
															) =>
																expenseSectionChanged(
																	type,
																	val,
																	multiple,
																	index
																)
															}
															onDelete={() =>
																deleteButtonClicked(
																	index,
																	"expense"
																)
															}
														/>
													</div>
												);
											}
									  )
									: ""}
								<div className="edit-form-add-btn-wrapper">
									<div
										onClick={addExpenseSection}
										className="edit-form-add-btn"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Add sx={{ marginRight: 0.5 }} />
										<p>New Expense</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="edit-btn-holder">
						{!noBudgets && (
							<div
								className="btn cancel-btn"
								onClick={() => navigate(-1)}
							>
								<p>Cancel</p>
							</div>
						)}
						<div
							className="btn save-btn"
							onClick={saveBudgetClicked}
						>
							<p>Save</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default AddNew;