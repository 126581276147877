import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { logout, signIn } from "../api/firestore";
import { User } from "firebase/auth";
import { auth } from "../FirebaseConfig";

export const useAuth = () => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    // Sign-in mutation
    const signInMutation = useMutation({
        mutationFn: async ({ email, password }: { email: string; password: string }) => {
            const user = await signIn(email, password);
            return user;
        },
    });

    // Logout mutation
    const logoutMutation = useMutation({
        mutationFn: async () => await logout(),
    });

    useEffect(() => {
        auth.onAuthStateChanged(function (user) {
            setCurrentUser(user ? user : null)
        });
    }, []);

    return {
        signIn: signInMutation.mutateAsync,
        signInStatus: signInMutation.status,
        signInError: signInMutation.error,
        logout: logoutMutation.mutateAsync,
        logoutStatus: logoutMutation.status,
        logoutError: logoutMutation.error,
        currentUser,
    };
};