import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from "./hoc/Layout";
import { NotificationProvider } from "./hooks/useNotification";
import "normalize.css";
import "./App.scss";

const queryClient = new QueryClient();

function App() {
	return (
		<NotificationProvider>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<div className="App">
						<Layout />
					</div>
				</QueryClientProvider>
			</BrowserRouter>
		</NotificationProvider>
	);
}

export default App;
