import { useState } from "react";

import { useNotification } from "../../../hooks/useNotification";
import { useAuth } from "../../../hooks/auth-hooks";
import { formatLoginError } from "../../../utils/utils";
import "./Login.scss";

const Login = () => {
	const { notify } = useNotification();
  const { signIn } = useAuth();

	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");

	const submitForm = async () => {
		if (userEmail && userPassword) {
			try {
				await signIn({ email: userEmail, password: userPassword});
			} catch (error: any) {
				notify(
					formatLoginError(error?.message) ||
						"Login failed. Please try again.",
					"error"
				);
			}
		} else {
			notify("Please enter both email and password.", "warning");
		}
	};

	return (
		<div className="login-wrapper">
			<h1 className="login-wrapper-title">Budgets By Me</h1>
			<div className="login-form">
				<h2>Sign In</h2>
				<div className="login-form-fieldset">
					<input
						placeholder="Email"
						value={userEmail}
						onChange={e => setUserEmail(e.target.value)}
						type={"email"}
					/>
					<input
						placeholder="Password"
						value={userPassword}
						onChange={e => setUserPassword(e.target.value)}
						type={"password"}
					/>
				</div>
				<div className="submit-btn" onClick={() => submitForm()}>
					<p>Submit</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
