import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../hooks/auth-hooks";
import { useGetBudgets } from "../hooks/budget-hooks";

interface BudgetContextType {
	budget: any;
	setBudget: (budget: any) => void;
    noBudgets: boolean;
    setNoBudgets: (val: boolean) => void;
	dateFilter: string;
	setDateFilter: (filter: string) => void;
}

const BudgetContext = createContext<BudgetContextType | undefined>(undefined);

export const BudgetProvider = ({ children }: { children: React.ReactNode }) => {
	const { currentUser } = useAuth();
	const { data: usersBudgets, isSuccess } = useGetBudgets(currentUser);

	const [budget, setBudget] = useState<any>(null);
    const [noBudgets, setNoBudgets] = useState(false);
	const [dateFilter, setDateFilter] = useState("Monthly");

	useEffect(() => {
		if (usersBudgets && usersBudgets.length > 0) {
			setBudget(usersBudgets[0]); // Default to the first budget
		} else if (isSuccess) {
            setNoBudgets(true);
        }
	}, [usersBudgets]);

	return (
		<BudgetContext.Provider
			value={{ budget, noBudgets, setNoBudgets, setBudget, dateFilter, setDateFilter }}
		>
			{children}
		</BudgetContext.Provider>
	);
};

export const useBudgetContext = () => {
	const context = useContext(BudgetContext);
	if (!context) {
		throw new Error(
			"useBudgetContext must be used within a BudgetProvider"
		);
	}
	return context;
};
