import { categoryOptions } from './constants';
import { BudgetObject, Payment } from './types';

export const formatPieData = (budget: BudgetObject) => {
    let pieData = [];
    let denom = 0;
    let numer = 0;
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    if (budget.income) {
        for (let i = 0; i < budget.income.length; i++) {
            const element = budget.income[i];
            if (element.frequency === 'monthly') {
                denom += +element.amount;
            } else if (element.frequency === 'bimonthly') {
                denom += +(element.amount * 2);
            } else if (element.frequency === 'quarterly') {
                denom += +(element.amount / 3);
            } else if (element.frequency === 'weekly') {
                let count = 0;
                let today = date.getDate();
                let todayWeek = date.getDay();
                let diff = todayWeek - element.dueDate;
                let firstDay = today - diff;
                let initial = new Date([year, month, firstDay].join('/'));

                while (initial.getMonth() + 1 == month) {
                    count++;
                    firstDay = firstDay - 7;
                    initial = new Date([year, month, firstDay].join('/'))
                }

                firstDay = today - diff + 7;
                initial = new Date([year, month, firstDay].join('/'));

                while (initial.getMonth() + 1 == month) {
                    count++;
                    firstDay = firstDay + 7;
                    initial = new Date([year, month, firstDay].join('/'))
                }
                denom += +(element.amount * count);
            } else if (element.frequency === 'annually') {
                if (element.dueDate[0] == month) {
                    denom += +element.amount;
                }
            }
        }
        for (let j = 0; j < budget.expenses.length; j++) {
            const element = budget.expenses[j];
            if (element.frequency === 'monthly') {
                numer += (+element.amount);
                let exists = false;
                for (let k = 0; k < pieData.length; k++) {
                    const existingData = pieData[k];
                    let formattedCat = getCategory(element.category);
                    if (existingData.name === formattedCat) {
                        existingData.value += (element.amount / denom) * 100;
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    let formattedCat = getCategory(element.category);
                    pieData.push({ name: formattedCat, value: (element.amount / denom) * 100 })
                }
            } else if (element.frequency === 'quarterly') {
                numer += (+element.amount / 3);
                let exists = false;
                for (let k = 0; k < pieData.length; k++) {
                    const existingData = pieData[k];
                    let formattedCat = getCategory(element.category);
                    if (existingData.name === formattedCat) {
                        existingData.value += ((element.amount / 3) / denom) * 100;
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    let formattedCat = getCategory(element.category);
                    pieData.push({ name: formattedCat, value: ((element.amount / 3) / denom) * 100 })
                }
            } else if (element.frequency === 'bimonthly') {
                numer += (+element.amount * 2);
                let exists = false;
                for (let k = 0; k < pieData.length; k++) {
                    const existingData = pieData[k];
                    let formattedCat = getCategory(element.category);
                    if (existingData.name === formattedCat) {
                        existingData.value += ((element.amount * 2) / denom) * 100;
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    let formattedCat = getCategory(element.category);
                    pieData.push({ name: formattedCat, value: ((element.amount * 2) / denom) * 100 })
                }
            } else if (element.frequency === 'weekly') {
                let count = 0;
                let today = date.getDate();
                let todayWeek = date.getDay();
                let diff = todayWeek - element.dueDate;
                let firstDay = today - diff;
                let initial = new Date([year, month, firstDay].join('/'));

                while (initial.getMonth() + 1 == month) {
                    count++;
                    firstDay = firstDay - 7;
                    initial = new Date([year, month, firstDay].join('/'))
                }

                firstDay = today - diff + 7;
                initial = new Date([year, month, firstDay].join('/'));

                while (initial.getMonth() + 1 == month) {
                    count++;
                    firstDay = firstDay + 7;
                    initial = new Date([year, month, firstDay].join('/'))
                }

                numer += (+element.amount * count);
                let exists = false;
                for (let k = 0; k < pieData.length; k++) {
                    const existingData = pieData[k];
                    let formattedCat = getCategory(element.category);
                    if (existingData.name === formattedCat) {
                        existingData.value += ((element.amount * count) / denom) * 100;
                        exists = true;
                        break;
                    }
                }
                if (!exists) {
                    let formattedCat = getCategory(element.category);
                    pieData.push({ name: formattedCat, value: ((element.amount * count) / denom) * 100 })
                }
            } else {
                if (element.dueDate[0] == month) {
                    numer += (+element.amount);
                    let exists = false;
                    for (let k = 0; k < pieData.length; k++) {
                        const existingData = pieData[k];
                        let formattedCat = getCategory(element.category);
                        if (existingData.name === formattedCat) {
                            existingData.value += (element.amount / denom) * 100;
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        let formattedCat = getCategory(element.category);
                        pieData.push({ name: formattedCat, value: (element.amount / denom) * 100 })
                    }
                }
                // numer += (+element.amount/12);
                // let formattedCat = getCategory(element.category);
                // pieData.push({ name: formattedCat, value: ((element.amount/12)/denom) * 100 })
            }
        }
        pieData.push({ name: 'Unallocated Income', value: ((denom - numer) / denom * 100) })

    } else {
        for (let j = 0; j < budget.expenses.length; j++) {
            const element = budget.expenses[j];
            if (element.frequency === 'monthly') {
                numer += +element.amount;
                let formattedCat = getCategory(element.category);
                pieData.push({ name: formattedCat, value: element.amount })
            } else {
                numer += (+element.amount / 12);
                let formattedCat = getCategory(element.category);
                pieData.push({ name: formattedCat, value: (element.amount / 12) })
            }
        }
    }
    let formatted = pieData.map(item => {
        return { name: item.name, value: +item.value.toFixed(1) };
    });
    return formatted.sort((a, b) => {
        return b.value - a.value;
    })
};

const getCategory = (val: string) => {
    for (let i = 0; i < categoryOptions.length; i++) {
        const element = categoryOptions[i];
        if (val === element.value) {
            return element.label;
        }
    }
};

export const alphabetize = (array: any[], value: string, direction: string = 'alphabetical') => {
    return [...array].sort((a, b) => {
        if (a[value] < b[value]) {
            return direction === 'reverse' ? 1 : -1;
        }
        if (a[value] > b[value]) {
            return direction === 'reverse' ? -1 : 1;
        }
        return 0;
    });
};


export const formatCardStats = (budget: any) => {
    let income = 0;
    let expenses = 0;
    let max = { label: budget.expenses[0].label, value: budget.expenses[0].amount };
    let min = { label: budget.expenses[0].label, value: budget.expenses[0].amount };
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    for (let i = 0; i < budget.income.length; i++) {
        const element = budget.income[i];
        if (element.frequency === 'weekly') {
            let count = 0;
            let today = date.getDate();
            let todayWeek = date.getDay();
            let diff = todayWeek - element.dueDate;
            let firstDay = today - diff;
            let initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                count++;
                firstDay = firstDay - 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            firstDay = today - diff + 7;
            initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                count++;
                firstDay = firstDay + 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            let amount = element.amount * count;
            income += amount;
        } else if (element.frequency === 'bimonthly') {
            income += (element.amount * 2);
        } else if (element.frequency === 'quarterly') {
            income += (element.amount / 3);
        } else if (element.frequency === 'annually') {
            if (element.dueDate[0] == month) {
                income += +element.amount;
            }
        } else {
            income += +element.amount;
        }
    };
    for (let j = 0; j < budget.expenses.length; j++) {
        const element = budget.expenses[j];
        if (element.frequency === 'weekly') {
            let count = 0;
            let today = date.getDate();
            let todayWeek = date.getDay();
            let diff = todayWeek - element.dueDate;
            let firstDay = today - diff;
            let initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                count++;
                firstDay = firstDay - 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            firstDay = today - diff + 7;
            initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                count++;
                firstDay = firstDay + 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            let amount = element.amount * count;
            expenses += amount;
        } else if (element.frequency === 'bimonthly') {
            expenses += (element.amount * 2);
        } else if (element.frequency === 'quarterly') {
            expenses += (element.amount / 3);
        } else if (element.frequency === 'annually') {
            let month = date.getMonth() + 1;
            if (element.dueDate[0] == month) {
                expenses += +element.amount;
            }
        } else {
            expenses += +element.amount;
        }
        // expenses += +element.amount;
        if (+max.value < +element.amount) {
            max.value = +element.amount;
            max.label = element.label;
        }
        if (+element.amount < +min.value) {
            min.value = +element.amount;
            min.label = element.label;
        }
    }
    return {
        income: income.toFixed(2),
        expenses: expenses.toFixed(2),
        min: min,
        max: max
    }
};

export const getMonthDay = (date: Date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}-${day}`;
}

export const reformatDates = (array: any[]) => {
    let holder: any[] = [];
    for (let i = 0; i < array.length; i++) {
        let element = { ...array[i] };
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        let temp = new Date([year, month, element.dueDate].join("-"));
        element.dueDate = temp;
        holder.push(element);
    }
    return holder;
};

const getFullDate = (day: any, frequency: string) => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    if (frequency === 'bimonthly') {
        let dayArray = [];
        for (let i = 0; i < day.length; i++) {
            let element = day[i];
            if (element === 'last') {
                let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                element = lastDayOfMonth.getDate();
            }
            if (element === 'first') {
                element = 1;
            }
            const formatted = new Date([year, month, element].join('/'));
            dayArray.push(formatted);
        }
        return dayArray;
    }
    else if (frequency === 'annually') {
        month = day[0];
        const formatted = new Date([year, month, day[1]].join('/'));
        return formatted;
    }
    else if (frequency === 'weekly') {
        let dayArray = [];
        let today = date.getDate();
        let todayWeek = date.getDay();

        let diff = todayWeek - day;
        let firstDay = today - diff;


        let initial = new Date([year, month, firstDay].join('/'));
        dayArray.push(new Date(initial.getTime() - 7 * 24 * 60 * 60 * 1000));
        dayArray.push(initial);
        dayArray.push(new Date(initial.getTime() + 7 * 24 * 60 * 60 * 1000));
        return dayArray;

    } else {
        const formatted = new Date([year, month, day].join('/'));
        return formatted;
    }
}

export const getCalendarDates2 = (
    obj: BudgetObject,
    startDate: Date,
    endDate: Date
): { dueDate: string; amount: number; label: string; type: string }[] => {
    let dates: { dueDate: string; amount: number; label: string; type: string }[] = [];

    const processPayment = (element: Payment, isExpense: boolean) => {
        const type = isExpense ? 'expense' : 'income';

        const addDateInRange = (date: Date, amount: number, label: string, type: string) => {
            if (date >= startDate && date <= endDate) {
                dates.push({
                    dueDate: date.toISOString().split('T')[0],
                    amount: amount,
                    label: label,
                    type: type,
                });
            }
        };

        if (element.frequency === 'weekly') {
            let currentDate = new Date(startDate);
            while (currentDate <= endDate) {
                if (currentDate.getDay() === element.dueDate) {
                    addDateInRange(currentDate, element.amount, element.label, type);
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else if (element.frequency === 'bimonthly') {
            const startYear = startDate.getFullYear();
            const startMonth = startDate.getMonth();
            const endYear = endDate.getFullYear();
            const endMonth = endDate.getMonth();

            for (let year = startYear; year <= endYear; year++) {
                let startMonthForYear = year === startYear ? startMonth : 0;
                let endMonthForYear = year === endYear ? endMonth : 11;
                for (let month = startMonthForYear; month <= endMonthForYear; month++) {
                    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
                    for (const day of element.dueDate) {
                        let paymentDay = day === 'last' ? lastDayOfMonth : day;
                        if (paymentDay > 0 && paymentDay <= lastDayOfMonth) {
                            addDateInRange(new Date(year, month, paymentDay), element.amount, element.label, type);
                        }
                    }
                }
            }
        } else if (element.frequency === 'monthly') {
            const startYear = startDate.getFullYear();
            const startMonth = startDate.getMonth();
            const endYear = endDate.getFullYear();
            const endMonth = endDate.getMonth();

            for (let year = startYear; year <= endYear; year++) {
                let startMonthForYear = year === startYear ? startMonth : 0;
                let endMonthForYear = year === endYear ? endMonth : 11;
                for (let month = startMonthForYear; month <= endMonthForYear; month++) {
                    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
                    let paymentDay = element.dueDate === 'last' ? lastDayOfMonth : element.dueDate;
                    if (paymentDay > 0 && paymentDay <= lastDayOfMonth) {
                        addDateInRange(new Date(year, month, paymentDay), element.amount, element.label, type);
                    }
                }
            }
        } else if (element.frequency === 'quarterly') {
            const startYear = startDate.getFullYear();
            const startMonth = startDate.getMonth();
            const endYear = endDate.getFullYear();
            const endMonth = endDate.getMonth();

            for (let year = startYear; year <= endYear; year++) {
                let startMonthForYear = year === startYear ? startMonth : 0;
                let endMonthForYear = year === endYear ? endMonth : 11;
                for (let month = startMonthForYear; month <= endMonthForYear; month++) {
                    if ([2, 5, 8, 11].includes(month)) { // Adjusted to match 0-indexed months
                        addDateInRange(new Date(year, month, element.dueDate), element.amount, element.label, type);
                    }
                }
            }
        } else if (element.frequency === 'annually') {
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();

            for (let year = startYear; year <= endYear; year++) {
                if (element.dueDate[0] - 1 >= 0 && element.dueDate[0] - 1 <= 11) { // Adjusted for 0-indexed months
                    addDateInRange(new Date(year, element.dueDate[0] - 1, element.dueDate[1]), element.amount, element.label, type);
                }
            }
        }
    };

    obj.income.forEach((income) => processPayment(income, false));
    obj.expenses.forEach((expense) => processPayment(expense, true));

    return dates;
};

export const getCalendarDates = (obj: any) => {
    let dates: any[] = [];
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    for (let i = 0; i < obj.income.length; i++) {
        const element = obj.income[i];
        if (element.frequency == 'weekly') {
            let today = date.getDate();
            let todayWeek = date.getDay();
            let diff = todayWeek - element.dueDate;
            let firstDay = today - diff;
            let initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                dates.push({ dueDate: initial, amount: element.amount, label: element.label, type: element.type });
                firstDay = firstDay - 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            firstDay = today - diff + 7;
            initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                dates.push({ dueDate: initial, amount: element.amount, label: element.label, type: element.type });
                firstDay = firstDay + 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

        }
        else if (element.frequency == 'bimonthly') {
            for (let i = 0; i < element.dueDate.length; i++) {
                const bimonthlyDate = element.dueDate[i];
                const formatted = new Date([year, month, bimonthlyDate].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }

        }
        else if (element.frequency === 'quarterly') {
            if (month == 3 || month == 6 || month == 9 || month == 12) {
                const formatted = new Date([year, month, element.dueDate].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }
        } else if (element.frequency === 'annually') {
            if (element.dueDate[0] == month) {
                const formatted = new Date([year, month, element.dueDate[1]].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }
        } else {
            const formatted = new Date([year, month, element.dueDate].join('/'));
            dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
        }
    }
    for (let i = 0; i < obj.expenses.length; i++) {
        const element = obj.expenses[i];
        if (element.frequency == 'weekly') {
            let today = date.getDate();
            let todayWeek = date.getDay();

            let diff = todayWeek - element.dueDate;
            let firstDay = today - diff;

            let initial = new Date([year, month, firstDay].join('/'));

            while (initial.getMonth() + 1 == month) {
                dates.push({ dueDate: initial, amount: element.amount, label: element.label, type: element.type });
                firstDay = firstDay - 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

            firstDay = today - diff + 7;
            initial = new Date([year, month, firstDay].join('/'));
            while (initial.getMonth() + 1 == month) {
                dates.push({ dueDate: initial, amount: element.amount, label: element.label, type: element.type });
                firstDay = firstDay + 7;
                initial = new Date([year, month, firstDay].join('/'))
            }

        }
        else if (element.frequency == 'bimonthly') {
            for (let i = 0; i < element.dueDate.length; i++) {
                const bimonthlyDate = element.dueDate[i];
                const formatted = new Date([year, month, bimonthlyDate].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }

        } else if (element.frequency === 'quarterly') {
            if (month == 3 || month == 6 || month == 9 || month == 12) {
                const formatted = new Date([year, month, element.dueDate].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }
        } else if (element.frequency === 'annually') {
            if (element.dueDate[0] == month) {
                const formatted = new Date([year, month, element.dueDate[1]].join('/'));
                dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
            }
        } else {
            const formatted = new Date([year, month, element.dueDate].join('/'));
            dates.push({ dueDate: formatted, amount: element.amount, label: element.label, type: element.type });
        }
    }

    console.log('dates', dates);
    return dates;
}

export const makeBudgetAnnual = (budget: any) => {
    let annual: BudgetObject = { ...budget };
    let newIncomes: any[] = [];
    let newExpenses: any[] = [];
    for (let i = 0; i < annual.expenses.length; i++) {
        let expense = { ...annual.expenses[i] };
        let annualAmount = +expense.amount * 12
        newExpenses.push({ ...expense, amount: annualAmount.toString() })
    }
    for (let j = 0; j < annual.income.length; j++) {
        let income = { ...annual.income[j] };
        let annualAmount = +income.amount * 12
        newIncomes.push({ ...income, amount: annualAmount.toString() })
    }
    annual = { ...annual, expenses: newExpenses, income: newIncomes };
    return annual;
};

export const formatUpcoming = (budgets: BudgetObject[] | undefined[]) => {
    let array: any[] = [];
    for (let i = 0; i < budgets.length; i++) {
        const element = budgets[i];
        if (element) {
            for (const key in element.expenses) {
                if (Object.prototype.hasOwnProperty.call(element.expenses, key)) {
                    let expense = { ...element.expenses[key], type: 'expense' };
                    let dates = getFullDate(expense.dueDate, expense.frequency);

                    if (Array.isArray(dates)) {
                        for (let j = 0; j < dates.length; j++) {
                            const singleDate = dates[j];
                            if (isDateWithinAWeek(singleDate)) {
                                expense.dueDate = singleDate;
                                array.push(expense);
                            }
                        }
                    } else {
                        if (isDateWithinAWeek(dates)) {
                            expense.dueDate = dates;
                            array.push(expense);
                        }
                    }
                }
            }
            for (const key in element.income) {
                if (Object.prototype.hasOwnProperty.call(element.income, key)) {
                    let expense = { ...element.income[key], type: 'income' };
                    let dates = getFullDate(expense.dueDate, expense.frequency);
                    if (Array.isArray(dates)) {
                        for (let j = 0; j < dates.length; j++) {
                            const singleDate = dates[j];
                            if (isDateWithinAWeek(singleDate)) {
                                expense.dueDate = singleDate;
                                array.push(expense);
                            }
                        }
                    } else {
                        if (isDateWithinAWeek(dates)) {
                            expense.dueDate = dates;
                            array.push(expense);
                        }
                    }
                }
            }
        }
    }
    console.log('upcoming array', array);
    array = dedupeExpenses(array);

    // array = array.slice(0, 4);
    let sorted = array.sort((a, b) => (a.dueDate > b.dueDate) ? 1 : -1)
    return sorted;
};

const dedupeExpenses = (arr: any) => {
    let newArray: any = [];
    for (let i = 0; i < arr.length; i++) {
        let added = false;
        if (newArray.length === 0) {
            newArray.push({ ...arr[i], group: [{ label: arr[i].label, amount: arr[i].amount, type: arr[i].type }] });
            continue;
        }
        for (let j = 0; j < newArray.length; j++) {
            if (getMonthDay(arr[i].dueDate) === getMonthDay(newArray[j].dueDate)) {
                added = true;
                if (newArray[j].group) {
                    newArray[j].group.push({ label: arr[i].label, amount: arr[i].amount, type: arr[i].type })
                } else {
                    newArray[j] = { ...newArray[j], group: [{ label: arr[i].label, amount: arr[i].amount, type: arr[i].type }] }
                }
            }
        }
        if (!added) {
            newArray.push({ ...arr[i], group: [{ label: arr[i].label, amount: arr[i].amount, type: arr[i].type }] })
        }

    }
    return newArray;
}

const isDateInThisWeek = (date: Date) => {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

const isDateWithinAWeek = (date: any) => {
    if (Array.isArray(date)) {
        for (let i = 0; i < date.length; i++) {
            const element = date[i];
            const oneWeek = new Date();
            oneWeek.setDate(oneWeek.getDate() + 7);
            if (element.getTime() <= oneWeek.getTime() && element.getTime() >= new Date().getTime()) {
                return element;
            }
        }
        return false;

    } else {
        const oneWeek = new Date();
        oneWeek.setDate(oneWeek.getDate() + 7);
        if (date.getTime() <= oneWeek.getTime() && date.getTime() >= new Date().getTime()) {
            return date;
        } else {
            return false;
        }
    }
}

export const formattedAmount = (budgetItem: any) => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;

    if (budgetItem.frequency === 'annually') {
        return budgetItem.dueDate[0] == month ? budgetItem.amount : null;
    } else if (budgetItem.frequency === 'monthly') {
        return budgetItem.amount;
    } else if (budgetItem.frequency === 'quarterly') {
        return Math.round(((budgetItem.amount / 3) + Number.EPSILON) * 100) / 100;
    } else if (budgetItem.frequency === 'bimonthly') {
        return budgetItem.amount * 2;
    } else if (budgetItem.frequency === 'weekly') {
        let count = 0;
        let today = date.getDate();
        let todayWeek = date.getDay();
        let diff = todayWeek - budgetItem.dueDate;
        let firstDay = today - diff;
        let initial = new Date([year, month, firstDay].join('/'));

        while (initial.getMonth() + 1 == month) {
            count++;
            firstDay = firstDay - 7;
            initial = new Date([year, month, firstDay].join('/'))
        }

        firstDay = today - diff + 7;
        initial = new Date([year, month, firstDay].join('/'));

        while (initial.getMonth() + 1 == month) {
            count++;
            firstDay = firstDay + 7;
            initial = new Date([year, month, firstDay].join('/'))
        }
        return budgetItem.amount * count;
    }
};

export const sortByValue = (items: any) => {
    let copy: any[] = [];
    for (let i = 0; i < items.length; i++) {
        let element = { ...items[i] };
        let newVal = formattedAmount(element);
        if (newVal) {
            element.amount = newVal;
            copy.push(element);
        }
    }
    return copy.sort((a: any, b: any) => {
        return b.amount - a.amount;
    })
};

export const formatLoginError = (error: string) => {
    if (error.includes("invalid-email") || error.includes("wrong-password")) {
        return "Your email/password is incorrect, please try again."
    }
    return error;
}

export const fixFullCalendarDate = (date: Date) => {
    let newDate = date;
    newDate.setMonth(date.getMonth() + 1);
    return newDate;
}