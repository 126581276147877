import CloseIcon from "@mui/icons-material/Close";

import "./Modal.scss";

const Modal = (props: any) => {
	return (
		<div className={`modal-overlay ${props.isOpen ? "modal-open" : ""}`}>
			<div className="modal-wrapper">
				<div className="modal-close">
					<CloseIcon onClick={() => props.closeClicked()} />
				</div>
				{props.children}
			</div>
		</div>
	);
};

export default Modal;
