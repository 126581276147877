import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createDoc, getDocs, getSingleDoc, updateDoc } from "../api/firestore";
import { User } from "firebase/auth";

export const useBudget = (budget_id: string | undefined) => {
    return useQuery({
        queryKey: ["budget", budget_id],
        queryFn: async () => {
            return await getSingleDoc("budget", budget_id)
        },
        enabled: !!budget_id
    })
};

export const useGetBudgets = (user: User | null) => {
    return useQuery({
        queryKey: ["budgets"],
        queryFn: async () => {
            return await getDocs("budget", [
                { field: "user_id", operator: "==", value: user?.uid },
                { field: "favorite", operator: "==", value: true },
            ]);
        },
        enabled: !!(user && user.uid)
    })
}

export const useUpdateBudget = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (data: any) => {
            return await updateDoc("budget", data);
        },
        onSettled: async (_, error) => {
            if (error) {
                console.log(error);
            }
            else {
                console.log("success");
                await queryClient.invalidateQueries({
                    queryKey: ["budgets"]
                })
            }
        }
    })
};

export const useCreateBudget = () => {
    return useMutation({
        mutationFn: async (data: any) => {
            return await createDoc("budget", data);
        },
        onSettled: async (_, error) => {
            if (error) {
                console.log(error);
            }
            else {
                console.log("success");

            }
        }
    })
}