import React, { useState, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { EventContentArg } from "@fullcalendar/core";

import { getCalendarDates2 } from "../../utils/utils";
import { BudgetObject } from "../../utils/types";
import './Calendar.scss';

interface BudgetCalendarProps {
	budget: BudgetObject;
	handleDateClick: (day:any) => void;
}

const BudgetCalendar = ({ budget, handleDateClick }: BudgetCalendarProps) => {
	const [events, setEvents] = useState<
		{ id: string; title: string; date: string; type: string }[]
	>([]);

	const generateEvents = useCallback(
		(startDate: Date, endDate: Date) => {
			if (!budget) return;

			const formattedEvents = getCalendarDates2(
				budget,
				startDate,
				endDate
			).map((entry, index) => ({
				id: `${entry.type}-${index}`,
				title: entry.label,
				date: entry.dueDate,
				type: entry.type,
			}));

			setEvents(formattedEvents);
		},
		[budget]
	);

	// Handle month navigation efficiently
	const handleDatesSet = (arg: {start: Date, end: Date }) => {
		generateEvents(arg.start, arg.end);
	};

	// Custom event rendering for better mobile UI
	const renderEventContent = (eventContent: EventContentArg) => {
		const eventType = (eventContent.event.extendedProps as any).type;
		const style = {
			backgroundColor: eventType === "income" ? "#30f552" : "#dd2c22",
			color: "#fff",
			outline: "none",
			padding: "2px 4px",
			borderRadius: "4px",
			fontSize: "0.8rem",
		};

		return <div style={style}>{eventContent.event.title}</div>;
	};

	return (
		<div style={{ width: "100%", maxWidth: "100%", overflowX: "auto" }}>
			<FullCalendar
				plugins={[dayGridPlugin, interactionPlugin]}
				initialView="dayGridMonth"
				events={events}
				dateClick={handleDateClick}
				eventClick={handleDateClick}
				eventContent={renderEventContent}
				height="auto"
				datesSet={handleDatesSet}
				dayMaxEventRows={3}
			/>
		</div>
	);
};

export default BudgetCalendar;
