import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../hooks/auth-hooks";
import { useBudgetContext } from "../../hooks/useBudgetContext";
import "./Navbar.scss";

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { logout } = useAuth();
	const { budget } = useBudgetContext();

	const formatTitle = () => {
		if (location.pathname.includes("new")) {
			return "Add Budget";
		} else if (location.pathname.includes("dashboard")) {
			return "Dashboard";
		} else if (location.pathname.includes("edit")) {
			return "Edit Budget";
		} else if (location.pathname.includes("/budget")) {
			return budget?.title;
		}
	};

	return (
		<div className="navbar-wrapper" style={{ zIndex: 100 }}>
			<h1 className="navbar-title">{formatTitle()}</h1>
			{/* {!location.pathname.includes("new") ? (
        <div
          className="navbar-element add-new-budget"
          onClick={() => navigate("/new")}
        >
          <p>New budget</p>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      ) : (
        ""
      )} */}
			<div className="navbar-btns">
				{/* <div className="navbar-element home-btn" onClick={() => navigate("/")}>
          Home
        </div> */}
				{location.pathname.includes("edit") ||
				location.pathname.includes("new") ? (
					""
				) : (
					<div
						className="navbar-element home-btn"
						onClick={() => navigate(`/edit/${budget.id}`)}
					>
						Edit Budget
					</div>
				)}
				<div onClick={() => logout()} className="logout-btn">
					Logout
				</div>
			</div>
		</div>
	);
};

export default Navbar;
