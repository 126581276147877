import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Budget.scss";
import Loader from "../../components/Loader/Loaders";
import PieGraph from "../../components/PieChart/Piechart";
import {
	formatCardStats,
	formatPieData,
	getCalendarDates,
	makeBudgetAnnual,
	formatUpcoming,
	sortByValue,
} from "../../utils/utils";
import { monthNames, pieChartColors } from "../../utils/constants";
import Modal from "../../components/Modal/Modal";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import { useBudgetContext } from "../../hooks/useBudgetContext";
import BudgetCalendar from "../../components/Calendar/BudgetCalendar";

const Budget = () => {
	const navigate = useNavigate();
	const { budget, noBudgets, dateFilter } = useBudgetContext();

	const [pieData, setPieData] = useState([] as any[]);
	const [cardStats, setCardStats] = useState({} as any);
	const [calendarData, setCalendarData] = useState([] as any[]);
	const [modalDateData, setModalDateData] = useState([] as any[]);
	const [modalDate, setModalDate] = useState("");
	const [modalDateOpen, setModalDateOpen] = useState(false);
	const [annualVersion, setAnnualVersion] = useState({} as any);
	const [upcoming, setUpcoming] = useState<any[] | undefined[]>([]);

	const showDayPlan = (dayObj: any) => {
		let day = dayObj.date ? dayObj.date : dayObj.event._instance.range.end;
		let dayPlan: any[] = [];
		for (let i = 0; i < calendarData.length; i++) {
			const element = calendarData[i];
			if (
				new Date(element.dueDate).toLocaleDateString("en-CA") ===
				new Date(day).toLocaleDateString("en-CA")
			) {
				dayPlan.push(element);
			}
		}

		if (dayPlan.length > 0) {
			const month = day.toLocaleString("default", { month: "long" }); // Abbreviated month name (e.g., Jan, Feb)
			const single_day = day.getDate();

			const formattedDate = `${month} ${single_day}`;
			setModalDate(formattedDate);
			setModalDateOpen(true);
			setModalDateData(dayPlan);
			return dayPlan;
		}
	};

	useEffect(() => {
		if (noBudgets) {
			navigate("/new");
		}
	}, [noBudgets]);

	useEffect(() => {
		if (budget) {
			setAnnualVersion(makeBudgetAnnual(budget));
			setPieData(formatPieData(budget));
			setCardStats(formatCardStats(budget));
			setCalendarData(getCalendarDates(budget));
			setUpcoming(formatUpcoming([budget]));
		}
	}, [budget]);

	useEffect(() => {
		if (dateFilter === "Annually") {
			setPieData(formatPieData(annualVersion));
			setCardStats(formatCardStats(annualVersion));
			setCalendarData(getCalendarDates(annualVersion));
		} else if (dateFilter === "Monthly" && budget) {
			setPieData(formatPieData(budget));
			setCardStats(formatCardStats(budget));
			setCalendarData(getCalendarDates(budget));
		}
	}, [dateFilter]);

	return (
		<>
			{budget && Object.keys(cardStats).length !== 0 ? (
				<div className="budget-wrapper">
					{/* <div className="budget-header">
              <div className="budget-header-btns">
                <ToggleSwitch
                  value={dateFilter}
                  option1="Monthly"
                  option2="Annually"
                  label={`budget-frequency`}
                  onChange={(val: string) =>
                    setDateFilter(
                      dateFilter === "Monthly" ? "Annually" : "Monthly"
                    )
                  }
                />
              </div>
            </div> */}
					<div className="budget-body">
						<div className="budget-upcoming">
							<h1>Upcoming</h1>
							<div className="budget-upcoming-calendar">
								{upcoming.length > 0 ? (
									upcoming.map((expense, i) => {
										if (expense) {
											return (
												<div
													key={i}
													className="budget-upcoming-date-wrapper"
												>
													<div className="budget-upcoming-date-day">
														<div className="budget-upcoming-date-day-top">
															<p>
																{expense.dueDate.getDate()}
															</p>
														</div>
														<div className="budget-upcoming-date-day-bottom">
															<p>
																{monthNames[
																	expense.dueDate.getMonth()
																].slice(0, 3)}
															</p>
														</div>
													</div>
													<div className="budget-upcoming-date-expenses">
														{expense.group.map(
															(
																grouped_expense: any,
																i: number
															) => {
																return (
																	<div
																		key={i}
																		className="budget-upcoming-date-expense"
																	>
																		<p>
																			{
																				grouped_expense.label
																			}
																		</p>
																		<p
																			className={
																				grouped_expense.type ===
																				"expense"
																					? "upcoming-expense-item"
																					: "upcoming-income-item"
																			}
																		>
																			{grouped_expense.type ===
																			"expense"
																				? "-"
																				: "+"}
																			$
																			{
																				grouped_expense.amount
																			}
																		</p>
																	</div>
																);
															}
														)}
													</div>
												</div>
											);
										} else {
											return "";
										}
									})
								) : (
									<div className="no-upcoming">
										No upcoming events
									</div>
								)}
							</div>
						</div>
						<div className="budget-calendar">
							{/* <p>Bill Calendar</p> */}
							<BudgetCalendar
								budget={budget}
								handleDateClick={showDayPlan}
							/>
							{/* <CalendarComponent
								showDayPlan={showDayPlan}
								dates={calendarData}
								showNavigation={false}
							/> */}
						</div>
						<div className="budget-cards">
							<div className="budget-card">
								<p>
									{dateFilter === "Monthly"
										? "Monthly"
										: "Annual"}{" "}
									Income
								</p>
								<h2 className="budget-card-green">
									+${cardStats.income}
								</h2>
							</div>
							<div className="budget-card">
								<p>
									{dateFilter === "Monthly"
										? "Monthly"
										: "Annual"}{" "}
									Spending
								</p>
								<h2 className="budget-card-red">
									-${cardStats.expenses}
								</h2>
							</div>
							<div className="budget-card">
								<p>Biggest Expense</p>
								<h2 className="budget-card-blue">
									{cardStats.max.label}
								</h2>
							</div>
							<div className="budget-card">
								<p>Smallest Expense</p>
								<h2 className="budget-card-blue">
									{cardStats.min.label}
								</h2>
							</div>
						</div>
						<div className="budget-graph-wrapper">
							<div className="budget-graph">
								<PieGraph data={pieData} />
							</div>
							<div className="budget-legend">
								{pieData.map(
									(dataPoint: any, index: number) => {
										return (
											<div
												key={index}
												className="budget-legend-item"
											>
												<div
													className="budget-legend-color"
													style={{
														backgroundColor:
															pieChartColors[
																index
															],
													}}
												></div>
												<p className="budget-legend-name">
													{dataPoint.name}
												</p>
												<p className="budget-legend-value">
													{dataPoint.value}%
												</p>
											</div>
										);
									}
								)}
							</div>
						</div>
						<div className="budget-elements">
							<div className="budget-expenses-list">
								<h2>Expenses</h2>
								<span></span>
								{sortByValue(budget.expenses).map(
									(single_exp: any, i: number) => {
										return (
											<div
												key={i}
												className="budget-list-item"
											>
												<p>{single_exp.label}</p>
												<p>{single_exp.amount}</p>
											</div>
										);
									}
								)}
							</div>
							<div className="budget-income-list">
								<h2>Income</h2>
								<span></span>
								{sortByValue(budget.income).map(
									(single_inc: any, i: number) => {
										return (
											<div
												key={i}
												className="budget-list-item"
											>
												<p>{single_inc.label}</p>
												<p>{single_inc.amount}</p>
											</div>
										);
									}
								)}
							</div>
						</div>
					</div>
					<Modal
						isOpen={modalDateOpen}
						closeClicked={() => setModalDateOpen(!modalDateOpen)}
					>
						<div className="date-selected-wrapper">
							<div className="date-selected">
								<h1>{modalDate}</h1>
							</div>
							<div className="date-data-wrapper">
								{modalDateData.map((data: any, i: number) => {
									return (
										<div className="date-data" key={i}>
											{data.type === "Income" ? (
												<div className="date-data-income">
													<p>{data.label}</p>
													<span className="date-data-spacer"></span>
													<h2>${data.amount}</h2>
												</div>
											) : (
												<div className="date-data-expense">
													<p>{data.label}</p>
													<span className="date-data-spacer"></span>
													<h2>${data.amount}</h2>
												</div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</Modal>
				</div>
			) : (
				<Loader height={"82vh"} width={"100%"} />
			)}
		</>
	);
};

export default Budget;
