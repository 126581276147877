import AuthLayout from "./AuthLayout";
import ContentLayout from "./ContentLayout";
import { useAuth } from "../hooks/auth-hooks";

const Layout = () => {
	const { currentUser } = useAuth();

	return <>{currentUser ? <ContentLayout /> : <AuthLayout />}</>;
};

export default Layout;
